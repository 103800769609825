
// Underline
.tit-wrap--underline {
    position: relative;
    padding-bottom: 14px;
    margin-bottom: var(--tit-wrap-padding-bottom);
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100vw;
        height: 1px;
        background-color: var(--border-color);
    }
    &.tit-wrap {
        &--xs {
            margin-bottom: var(--tit-wrap-padding-bottom--xs);
        }
        &--sm {
            margin-bottom: var(--tit-wrap-padding-bottom--sm);
        }
        &--lg {
            margin-bottom: var(--tit-wrap-padding-bottom--lg);
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .tit-wrap--underline {
        padding-bottom: 18px;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
