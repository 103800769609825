
:deep() {
    .v-field--active {
        .v-label.v-field-label.v-field-label--floating {
            width: 0;
            margin: 0 !important;
        }
        .v-field__input {
            align-items: center;
        }
    }
}
