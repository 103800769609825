
.aside-list {
    padding: 0;
    ::v-deep {
        .v-list-item {
            min-height: 28px;
            font-size: 1.4rem !important;
            padding: 0 16px !important;
            &__title {
                font-size: inherit;
            }
        }
        > .v-list-item,
        > .v-list-group {
            padding: 10px 0;
            // border-bottom: 1px solid var(--border-color);
        }
        > .v-list-item,
        > .v-list-group > .v-list-group__header {
            font-weight: 500;
            color: var(--v-grey-lighten1);
        }
        > .v-list-group {
            > .v-list-group__header {
                pointer-events: none;
                cursor: default;
                .v-icon {
                    display: none;
                }
            }
            .aside-list {
                &--active {
                    font-weight: 400 !important;
                    color: var(--v-grey-darken4) !important;
                    text-decoration: underline;
                }
            }
        }
        > .v-list-item {
            min-height: 54px;
        }

        .v-list-item {
            &__action,
            &__avatar,
            &__icon {
                min-width: 20px;
            }
            &__action,
            &__icon {
                &:first-child {
                    margin-right: 10px;
                }
            }
            &__icon {
                margin: auto 0;
            }
        }
        .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
            color: var(--v-grey-lighten1) !important;
        }
    }

    &--active {
        color: var(--v-on-primary) !important;
        background-color: var(--v-primary-base) !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .aside-list {
        ::v-deep {
            .v-list-item {
                font-size: 1.6rem !important;
            }
            > .v-list-item,
            > .v-list-group {
                padding: 20px 0;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
